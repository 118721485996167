<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />

        <h2 class="brand-text text-primary ml-1">
          {{ appName }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            {{ appName }}에 오신걸 환영합니다.
          </b-card-title>
          <b-card-text class="mb-2">
            가입후 상세정보를 등록할 수 있습니다.
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="registerForm"
            #default="{ invalid }"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="register"
            >
              <!-- username -->
              <b-form-group
                label="이름"
                label-for="register-username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="userName"
                  vid="userName"
                  rules="required"
                >
                  <b-form-input
                    id="register-username"
                    v-model="userName"
                    name="register-username"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- userid -->
              <b-form-group
                label="아이디"
                label-for="register-userid"
              >
                <validation-provider
                  #default="{ errors }"
                  name="userId"
                  vid="userId"
                  rules="required"
                >
                  <b-form-input
                    id="register-userid"
                    v-model="userId"
                    name="register-userid"
                    :state="errors.length > 0 ? false : null"
                    placeholder="아이디"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label-for="register-password"
                label="Password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="register-password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid"
              >
                회원 가입
              </b-button>
            </b-form>
          </validation-observer>

          <!-- <p class="text-center mt-2">
            <span>Already have an account?</span>
            <b-link :to="{ name: 'auth-login' }">
              <span>&nbsp;Sign in instead</span>
            </b-link>
          </p> -->
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
  /* eslint-disable global-require */
  import { ValidationProvider, ValidationObserver } from 'vee-validate';
  import VuexyLogo from '@core/layouts/components/Logo.vue';
  import {
    BRow,
    BCol,
    BLink,
    BButton,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BCardTitle,
    BCardText,
  } from 'bootstrap-vue';
  import { required } from '@validations';
  import { togglePasswordVisibility } from '@core/mixins/ui/forms';
  import store from '@/store/index';
  import useJwt from '@/auth/jwt/useJwt';
  import { $themeConfig } from '@themeConfig';

  export default {
    components: {
      VuexyLogo,
      BRow,
      BImg,
      BCol,
      BLink,
      BButton,
      BForm,
      BCardText,
      BCardTitle,
      BFormCheckbox,
      BFormGroup,
      BFormInput,
      BInputGroup,
      BInputGroupAppend,
      // validations
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
      const { appName, appLogoImage } = $themeConfig.app;

      return {
        userName: '',
        userId: '',
        password: '',
        sideImg: require('@/assets/images/pages/register-v2.svg'),
        // validation
        required,
        appName,
        appLogoImage,
      };
    },
    computed: {
      passwordToggleIcon() {
        return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
      },
      imgUrl() {
        if (store.state.appConfig.layout.skin === 'dark') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.sideImg = require('@/assets/images/pages/register-v2-dark.svg');
          return this.sideImg;
        }
        return this.sideImg;
      },
    },
    methods: {
      register() {
        this.$refs.registerForm.validate().then(success => {
          if (success) {
            useJwt
              .register({
                branchId: 3,
                userId: this.userId,
                userName: this.userName,
                password: this.password,
              })
              .then(response => {
                // console.dir(response);
                // useJwt.setToken(response.data.accessToken);
                // useJwt.setRefreshToken(response.data.refreshToken);
                // localStorage.setItem(
                //   'userData',
                //   JSON.stringify(response.data.userData)
                // );
                // this.$ability.update(response.data.userData.ability);
                this.$router.push({ name: 'auth-login' });
              })
              .catch(error => {
                this.$refs.registerForm.setErrors(error.response.data.error);
              });
          }
        });
      },
    },
  };
  /* eslint-disable global-require */
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
</style>
